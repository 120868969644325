/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/de'

import { MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'

import Button from '../../components/Button'
import Icon from '../../components/Icon'
import RadioGroup from '../../components/RadioGroup'
import { useWindowSize } from '../../hooks/useWindowSize'

const languages = ['de', 'en']

type LanguageModalProps = {
  showModal: boolean
  onChangeLanguage: (language: string) => void
  onClose: () => void
  modalTitle: string
  confirmText: string
  activeLanguage: string | null
}

function LanguageModal({
  onClose,
  showModal,
  modalTitle,
  confirmText,
  onChangeLanguage,
  activeLanguage,
}: LanguageModalProps) {
  const modalRef = useRef<HTMLDialogElement>(null)
  const [closeAnimationPlaying, setCloseAnimationPlaying] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(activeLanguage || 'de')
  const { isDesktop } = useWindowSize()

  useEffect(() => {
    if (showModal && modalRef.current !== null) {
      modalRef.current.showModal()
      modalRef.current.classList.add(isDesktop ? 'openDownToUp' : 'openRightToLeft')
    }
  }, [isDesktop, showModal])

  const onModalCloseClick = useCallback(() => {
    modalRef?.current?.classList.add(isDesktop ? 'closeDownToUp' : 'closeRightToLeft')

    setCloseAnimationPlaying(true)
  }, [isDesktop])

  const onEscapeClose = useCallback(
    (e: SyntheticEvent<HTMLDialogElement>) => {
      e.preventDefault()
      onModalCloseClick()
    },
    [onModalCloseClick],
  )

  const onModalClick = useCallback(
    (event: MouseEvent<HTMLDialogElement>) => {
      if (!modalRef.current) return

      // check if we click on backdrop
      const rect = modalRef.current.getBoundingClientRect()

      if (
        event.clientX < rect.left ||
        event.clientX > rect.right ||
        event.clientY < rect.top ||
        event.clientY > rect.bottom
      ) {
        onModalCloseClick()
      }
    },
    [onModalCloseClick],
  )

  const onCloseAnimationEnd = useCallback(() => {
    if (!modalRef.current || !closeAnimationPlaying) return

    modalRef.current.close()
    setCloseAnimationPlaying(false)

    onClose()
  }, [closeAnimationPlaying, onClose])

  const onSubmit = useCallback(() => {
    onChangeLanguage(selectedLanguage)

    onModalCloseClick()
  }, [onChangeLanguage, selectedLanguage, onModalCloseClick])

  function localeToName(locale: string) {
    const displayNames = new Intl.DisplayNames([locale], { type: 'language' })
    const converted = displayNames.of(locale)

    return converted ? converted.charAt(0).toUpperCase() + converted.slice(1) : ''
  }

  return (
    <dialog
      ref={modalRef}
      onAnimationEnd={onCloseAnimationEnd}
      className='fixed m-auto h-full max-h-full w-full max-w-full bg-lightgray backdrop:bg-[#00000080] xl:m-auto xl:h-fit xl:w-[640px]'
      onClick={onModalClick}
      onCancel={onEscapeClose}
    >
      <div className='relative flex h-full flex-col rounded-md bg-lightgray p-5 text-left xl:p-10'>
        <button className='absolute right-6 top-6 ml-auto' onClick={onModalCloseClick} autoFocus>
          <Icon name='MdClose' size={20} />
        </button>

        <h5 className='mb-10 mt-4'>{modalTitle}</h5>

        <RadioGroup
          data={languages.map(language => ({ label: localeToName(language), value: language }))}
          selectedItemValue={selectedLanguage}
          onChange={value => setSelectedLanguage(value)}
        />

        <Button
          appearance='dark'
          onPress={onSubmit}
          className='mt-auto !w-full !max-w-full !py-4 xl:mt-10'
        >
          {confirmText}
        </Button>
      </div>
    </dialog>
  )
}

export default LanguageModal
