import clsx from 'clsx'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'
import { useCallback, useRef, useState } from 'react'
import { Cart } from 'shopify-types'

import { debounce } from '../../helpers/debounce'
import ProductListItem from './ProductListItem'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type ProductListProps = {
  isLoading: boolean
  cart: Cart | null
  deleteItemText: string
  maxPerItemInCart: number
  accessability: {
    addItemButton: string
    subtractItemButton: string
    deleteItemButton: string
    countIndicator: string
  }
  onItemDelete: (data: { productId: string; variantId?: string | undefined }) => Promise<void>
  onItemQuantityUpdate: (
    data: Array<{
      quantity: number
      productId: string
      variantId: string
    }>,
  ) => Promise<void>
}

export default function ProductList({
  cart,
  isLoading,
  deleteItemText,
  maxPerItemInCart,
  onItemDelete,
  onItemQuantityUpdate,
  accessability,
}: ProductListProps) {
  const [isQuantityUpdated, setIsQuantityUpdated] = useState(false)

  const updateItemsIds = useRef<
    Array<{
      quantity: number
      productId: string
      variantId: string
    }>
  >([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateQuantity = useCallback(
    debounce(
      (
        data: Array<{
          quantity: number
          productId: string
          variantId: string
        }>,
      ) => {
        setIsQuantityUpdated(false)
        onItemQuantityUpdate(data)
        updateItemsIds.current = []
      },
      1000,
    ),
    [],
  )

  const handleItemUpdate = useCallback(
    (data: { quantity: number; productId: string; variantId: string }) => {
      setIsQuantityUpdated(true)

      const { variantId: updatedItemVariantId, ...restData } = data

      const index = updateItemsIds.current.findIndex(
        ({ variantId }) => variantId === updatedItemVariantId,
      )

      if (index !== -1) {
        updateItemsIds.current[index] = { ...restData, variantId: updatedItemVariantId }
      } else {
        updateItemsIds.current.push({ ...restData, variantId: updatedItemVariantId })
      }

      debouncedUpdateQuantity(updateItemsIds.current)
    },
    [debouncedUpdateQuantity],
  )

  return (
    <LazyMotion features={motionFeatures} strict>
      <ul className='flex grow flex-col divide-y divide-lightgray'>
        <AnimatePresence mode='popLayout'>
          {cart?.lines.map(({ id, merchandise: variant, quantity }) => (
            <m.li
              key={id}
              layout
              initial={{ scaleY: 0, opacity: 0 }}
              animate={{ scaleY: 1, opacity: 1 }}
              exit={{ scaleY: 0, opacity: 0 }}
              style={{ originY: 0 }}
              transition={{ duration: 0.3 }}
              className={clsx(cart.lines.length === 1 ? 'p-0' : 'py-5')}
            >
              <ProductListItem
                productId={variant.product.id}
                variantId={variant.id}
                title={variant.product.title}
                isLoading={isLoading}
                description={variant.title}
                quantity={quantity}
                price={variant.price.amount}
                currency={variant.price.currencyCode}
                image={variant.image.src}
                imageAlt={variant.image.altText}
                deleteButtonText={deleteItemText}
                onItemDelete={onItemDelete}
                onItemQuantityUpdate={handleItemUpdate}
                isQuantityUpdated={isQuantityUpdated}
                maxPerItemInCart={maxPerItemInCart}
                accessability={accessability}
              />
            </m.li>
          ))}
        </AnimatePresence>
      </ul>
    </LazyMotion>
  )
}
