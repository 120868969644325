import clsx from 'clsx'
import { Image } from 'cms-types'
import Link from 'next/link'
import { ElementRef, useLayoutEffect, useRef } from 'react'
import { MdClose, MdDehaze, MdOutlineLogout } from 'react-icons/md'

import { PayloadImage } from '../../components/PayloadImage'
import { ProfileIcon } from '../../custom-icons/ProfileIcon'
import { useWindowSize } from '../../hooks/useWindowSize'
import NavLinkList from './NavLinkList'
import SearchButton from './SearchButton'
import ShoppingBasketButton from './ShoppingBasketButton'

type TopNavBarProps = {
  isBurgerMenuOpen: boolean
  onOpenBurgerMenu: () => void
  onCloseBurgerMenu: () => void
  searchEnabled?: boolean | null
  isSearchBarOpen: boolean
  searchButtonLabel?: string
  onOpenSearch: () => void
  onCloseSearch: () => void
  shoppingBasketEnabled?: boolean | null
  shoppingBasketItemsCount?: number
  onShoppingIconClick: () => void
  loginEnabled?: boolean | null
  menuItems?: {
    id?: string | null
    url: string
    title: string
    isActive?: boolean | undefined
  }[]
  logo?: Image
  logoUrl: string
  isPreview?: boolean
  isLogin: boolean
  goToLogin?: () => void
  goToLogout?: () => void
  goToProfile?: () => void
}

export default function TopNavBar({
  isBurgerMenuOpen,
  onOpenBurgerMenu,
  onCloseBurgerMenu,
  searchEnabled = false,
  isSearchBarOpen,
  onOpenSearch,
  onCloseSearch,
  searchButtonLabel,
  shoppingBasketEnabled = false,
  shoppingBasketItemsCount,
  onShoppingIconClick,
  loginEnabled = false,
  menuItems,
  logo,
  logoUrl,
  isPreview = false,
  isLogin,
  goToLogin,
  goToLogout,
  goToProfile,
}: TopNavBarProps) {
  const { isDesktop, windowSize } = useWindowSize()

  const navBarRef = useRef<ElementRef<'div'>>(null)

  useLayoutEffect(() => {
    if (navBarRef.current == null) return

    if (!isDesktop) {
      document.documentElement.style.setProperty(
        '--scroll-padding-top',
        `${navBarRef.current.offsetHeight + 1}px`,
      )
    } else {
      document.documentElement.style.removeProperty('--scroll-padding-top')
    }
  }, [isDesktop, windowSize])

  return (
    <div
      ref={navBarRef}
      className='relative z-40 flex items-center justify-between bg-white py-4 pl-5 pr-3 md:px-5 xl:p-10'
    >
      <button
        aria-label={isBurgerMenuOpen ? 'Close Menu' : 'Open Menu'}
        className='relative block h-6 w-6 cursor-pointer overflow-hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 xl:hidden'
        onClick={isBurgerMenuOpen ? onCloseBurgerMenu : onOpenBurgerMenu}
      >
        <div
          className={clsx(
            'absolute flex -translate-x-1/2 -translate-y-1/2 items-center transition-all duration-500',
            isBurgerMenuOpen ? 'left-full' : 'left-0',
          )}
        >
          <MdClose size={24} />

          <MdDehaze size={24} />
        </div>
      </button>

      <Link
        href={logoUrl}
        className='absolute bottom-2/4 right-2/4 flex h-auto translate-x-1/2 translate-y-1/2 xl:static xl:translate-x-0 xl:translate-y-0'
      >
        <PayloadImage
          src={logo}
          skipBlur
          sizes='(min-width: 1280px) 224px, 128px'
          className='h-6 w-32 xl:h-10 xl:w-56'
        />
      </Link>

      <NavLinkList menuItems={menuItems} onClick={onCloseSearch} isPreview={isPreview} />

      <ul className={clsx('flex gap-4 md:gap-6', { 'xl:gap-5': isLogin, 'xl:gap-10': !isLogin })}>
        {searchEnabled && (
          <li className='flex'>
            <SearchButton
              onPress={isSearchBarOpen ? onCloseSearch : onOpenSearch}
              className='hover:opacity-80'
              searchButtonLabel={searchButtonLabel}
            />
          </li>
        )}

        {loginEnabled && (
          <li className='hidden xl:flex'>
            <button onClick={isLogin ? goToProfile : goToLogin} aria-label='Profile'>
              <ProfileIcon size={24} className='hover:opacity-80' />
            </button>
          </li>
        )}

        {loginEnabled && isLogin && (
          <li className='hidden xl:flex' aria-label='Log out'>
            <button onClick={goToLogout}>
              <MdOutlineLogout size={24} className='!font-light hover:opacity-80' />
            </button>
          </li>
        )}

        {shoppingBasketEnabled && (
          <li className='flex'>
            <ShoppingBasketButton
              itemsCount={shoppingBasketItemsCount}
              onClick={onShoppingIconClick}
              className='hover:opacity-80'
            />
          </li>
        )}
      </ul>
    </div>
  )
}
