import clsx from 'clsx'
import Link from 'next/link'

import { ProfileIcon } from '../../custom-icons/ProfileIcon'
import { useWindowSize } from '../../hooks/useWindowSize'

type BurgerMenuProps = {
  isOpen: boolean
  isCloseAnimationStarted: boolean
  menuItems?: {
    id?: string | null
    url: string
    title: string
    isActive?: boolean | undefined
  }[]
  additionalListItems?: {
    id?: string | null
    url: string
    title: string
  }[]
  loginEnabled: boolean
  loginTypography?: {
    login: string
    profile: string
    logout: string
  }
  isLogin: boolean
  /**
   * This callback will be invoke whenever we click on burger menu item
   */
  onCloseAnimationEnd: () => void
  onNavigation?: () => void
  goToLogin?: () => void
  goToLogout?: () => void
  goToProfile?: () => void
}

export default function BurgerMenu({
  isOpen,
  isCloseAnimationStarted,
  onNavigation,
  onCloseAnimationEnd,
  menuItems,
  additionalListItems,
  loginEnabled,
  loginTypography,
  isLogin,
  goToLogin,
  goToLogout,
  goToProfile,
}: BurgerMenuProps) {
  const { isDesktop } = useWindowSize()

  if (isDesktop) {
    return null
  }

  const onProfileClick = () => {
    isLogin ? goToProfile?.() : goToLogin?.()

    onNavigation && onNavigation()
  }

  return (
    <nav
      className={clsx(
        'absolute z-40 flex h-screen w-full flex-col gap-12 overflow-y-auto bg-white px-5 pb-20 pt-12',
        isOpen && !isCloseAnimationStarted ? 'openRightToLeft' : 'closeRightToLeft',
      )}
      onAnimationEnd={onCloseAnimationEnd}
    >
      <ul className='flex flex-col gap-6'>
        {menuItems?.map(({ url, title, id }) => (
          <li key={id ?? url} className='h-max'>
            <Link
              href={url}
              onClick={onNavigation}
              className='block rounded-pill text-black no-underline transition-colors hover:opacity-80'
            >
              <h2 className='h2'>{title}</h2>
            </Link>
          </li>
        ))}
      </ul>

      <ul className='flex flex-col gap-5'>
        {additionalListItems?.map(({ url, title, id }) => (
          <li key={id ?? url} className='h-max'>
            <Link
              href={url}
              onClick={onNavigation}
              className='block rounded-pill text-black no-underline transition-colors hover:opacity-80'
            >
              <p className='h5'>{title}</p>
            </Link>
          </li>
        ))}
      </ul>

      {loginEnabled && (
        <div className='flex w-max gap-5'>
          <button
            onClick={onProfileClick}
            className="relative flex items-center gap-2.5 after:absolute after:top-full after:block after:h-px after:w-full after:bg-black after:content-[''] hover:opacity-80"
          >
            <ProfileIcon size={20} />

            {loginTypography && (
              <p className='lg'>{isLogin ? loginTypography.profile : loginTypography.login}</p>
            )}
          </button>

          {isLogin && loginTypography && (
            <>
              <span>
                <p className='lg select-none'>•</p>
              </span>

              <button
                onClick={goToLogout}
                className="relative flex items-center gap-2.5 after:absolute after:top-full after:block after:h-px after:w-full after:bg-black after:content-[''] hover:opacity-80"
              >
                <p className='lg'>{loginTypography.logout}</p>
              </button>
            </>
          )}
        </div>
      )}
    </nav>
  )
}
