'use client'

import { Image } from 'cms-types'
import { useCallback, useEffect, useState } from 'react'

import modalService from '../../service/modalService'
import BurgerMenu from './BurgerMenu'
import SearchBar from './SearchBar'
import TopNavBar from './TopNavBar'

type NavigationProps = {
  onShoppingBasketIconClick: () => void
  shoppingBasketItemsCount?: number
  onSearchSubmit: (query: string) => void
  logo?: Image
  logoUrl: string
  menuItems?: {
    id?: string | null
    url: string
    title: string
    isActive?: boolean
  }[]
  burgerMenuAdditionalListItems?: {
    id?: string | null
    url: string
    title: string
  }[]
  searchEnabled?: boolean | null
  searchFieldPlaceholder?: string | null
  searchButtonLabel?: string
  closeSearchButtonLabel?: string
  searchSuggestion?: {
    title: string
    links: {
      title: string
      id?: string | null
    }[]
  }
  shoppingBasketEnabled?: boolean | null
  loginEnabled?: boolean | null
  loginTypography?: {
    login: string
    profile: string
    logout: string
  }
  isLogin: boolean
  /**
   * Specific variable for Storybook preview
   */
  isPreview?: boolean
  goToLogout: () => void
  goToLogin: () => void
  goToProfile: () => void
}

export default function Navigation({
  onShoppingBasketIconClick,
  shoppingBasketItemsCount,
  onSearchSubmit,
  logo,
  logoUrl,
  menuItems,
  burgerMenuAdditionalListItems,
  searchEnabled,
  searchFieldPlaceholder,
  searchButtonLabel,
  closeSearchButtonLabel,
  shoppingBasketEnabled,
  loginEnabled,
  loginTypography,
  isLogin,
  isPreview,
  searchSuggestion,
  goToLogin,
  goToLogout,
  goToProfile,
}: NavigationProps) {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
  const [isSearchBarCloseAnimationStarted, setIsSearchBarOpenAnimationStarted] = useState(false)

  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const [isBurgerMenuCloseAnimationStarted, setIsBurgerMenuCloseAnimationStarted] = useState(false)

  const onOpenBurgerMenu = useCallback(() => {
    modalService.changeModalState('burgerMenu', true)
    setBurgerMenuOpen(true)
    setIsBurgerMenuCloseAnimationStarted(false)
    document.body.classList.add('cancelScroll')
  }, [])

  const onCloseBurgerMenu = useCallback(() => {
    setIsBurgerMenuCloseAnimationStarted(true)
  }, [])

  const onCloseBurgerMenuAnimationEnd = useCallback(() => {
    if (isBurgerMenuCloseAnimationStarted) {
      setBurgerMenuOpen(false)
      modalService.changeModalState('burgerMenu', false)
      setIsBurgerMenuCloseAnimationStarted(false)
    }
  }, [isBurgerMenuCloseAnimationStarted])

  const onOpenSearchBar = useCallback(() => {
    modalService.changeModalState('searchBar', true)
    setIsSearchBarOpen(true)
    setIsSearchBarOpenAnimationStarted(false)

    if (!document.body.classList.contains('cancelScroll')) {
      document.body.classList.add('cancelScroll')
    }
  }, [])

  const onCloseSearchBar = useCallback(() => {
    setIsSearchBarOpenAnimationStarted(true)
  }, [])

  const onSearchBarCloseAnimationEnd = useCallback(() => {
    if (isSearchBarCloseAnimationStarted) {
      setIsSearchBarOpenAnimationStarted(false)
      setIsSearchBarOpen(false)
      modalService.changeModalState('searchBar', false)
    }
  }, [isSearchBarCloseAnimationStarted])

  useEffect(() => {
    modalService.addActionOnScreenChange('burgerMenu', {
      onCloseDesktop: () => {
        setBurgerMenuOpen(false)
      },
    })
  }, [])

  useEffect(() => {
    if (!searchEnabled) return

    const searchOpenWithKeyboard = (keyboardEvent: KeyboardEvent) => {
      if (keyboardEvent.ctrlKey && keyboardEvent.key === 'k') {
        keyboardEvent.preventDefault()

        onOpenSearchBar()
      }
    }

    window.addEventListener('keydown', searchOpenWithKeyboard)

    return () => {
      window.removeEventListener('keydown', searchOpenWithKeyboard)
    }
  }, [onOpenSearchBar, searchEnabled])

  return (
    <nav className='relative w-full'>
      <TopNavBar
        isBurgerMenuOpen={isBurgerMenuOpen && !isBurgerMenuCloseAnimationStarted}
        onOpenBurgerMenu={onOpenBurgerMenu}
        shoppingBasketItemsCount={shoppingBasketItemsCount}
        onCloseBurgerMenu={onCloseBurgerMenu}
        searchEnabled={searchEnabled}
        onOpenSearch={onOpenSearchBar}
        onCloseSearch={onCloseSearchBar}
        isSearchBarOpen={isSearchBarOpen}
        searchButtonLabel={searchButtonLabel}
        shoppingBasketEnabled={shoppingBasketEnabled}
        onShoppingIconClick={onShoppingBasketIconClick}
        menuItems={menuItems}
        logo={logo}
        logoUrl={logoUrl}
        isPreview={isPreview}
        loginEnabled={loginEnabled}
        isLogin={isLogin}
        goToLogin={goToLogin}
        goToLogout={goToLogout}
        goToProfile={goToProfile}
      />

      {isSearchBarOpen && (
        <SearchBar
          isOpen={isSearchBarOpen}
          isCloseAnimationStarted={isSearchBarCloseAnimationStarted}
          onClose={onCloseSearchBar}
          onCloseAnimationEnd={onSearchBarCloseAnimationEnd}
          onSubmit={onSearchSubmit}
          placeholder={searchFieldPlaceholder}
          closeButtonLabel={closeSearchButtonLabel}
          searchButtonLabel={searchButtonLabel}
          searchSuggestion={searchSuggestion}
        />
      )}

      {isBurgerMenuOpen && (
        <BurgerMenu
          isOpen={isBurgerMenuOpen}
          isCloseAnimationStarted={isBurgerMenuCloseAnimationStarted}
          onCloseAnimationEnd={onCloseBurgerMenuAnimationEnd}
          menuItems={menuItems}
          additionalListItems={burgerMenuAdditionalListItems}
          loginEnabled={Boolean(loginEnabled)}
          loginTypography={loginTypography}
          isLogin={isLogin}
          onNavigation={onCloseBurgerMenu}
          goToLogin={goToLogin}
          goToLogout={goToLogout}
          goToProfile={goToProfile}
        />
      )}
    </nav>
  )
}
