'use client'
import clsx from 'clsx'
import { ChangeEvent, useCallback, useRef } from 'react'
import { useTextField } from 'react-aria'

import { SearchIcon } from '../../custom-icons/SearchIcon'
import LoadingText from '../LoadingText'

type InputProps = {
  label?: string
  errorMessage?: string
  value: string
  onChange: (value: string) => void
  rightButtonProps?: {
    text: string
    onClick: () => void
  }
  isDisabled?: boolean
  isTransparent?: boolean
  rounded?: boolean
  withMagnifier?: boolean
  type?: string
  className?: string
  name?: string
  loading?: boolean
}

export default function Input(props: InputProps) {
  const ref = useRef(null)
  const { labelProps, inputProps, errorMessageProps } = useTextField(props, ref)

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange(event.target.value)
    },
    [props],
  )

  return (
    <div
      className={clsx(
        'isolate flex flex-col gap-1 sm:max-w-[335px] xl:max-w-[440px]',
        props.className,
      )}
    >
      <label
        className={clsx(
          'relative flex outline outline-1 !transition-all',
          props.errorMessage ? 'outline-error' : ' outline-darkgray focus-within:outline-black',
          props.isDisabled ? 'bg-lightgray' : 'bg-white',
          props.isTransparent && '!bg-transparent',
          props.rounded ? 'radius-pill' : 'radius-xs',
        )}
        {...labelProps}
      >
        <input
          {...inputProps}
          value={props.value}
          className={clsx(
            'peer w-full pb-[7px] pt-[22px] text-[18px] leading-[150%] placeholder:opacity-0 focus:outline-none',
            props.rightButtonProps?.text ? 'pr-1' : 'pr-4',
            props.rounded ? 'radius-pill' : 'radius-xs',
            props.withMagnifier ? 'pl-14' : 'pl-4',
          )}
          style={{
            backgroundColor: 'transparent',
          }}
          onChange={onChange}
          ref={ref}
          placeholder={props.label}
          type={props.type}
        />

        {props.withMagnifier && (
          <div className='absolute left-5 top-4 cursor-text'>
            <SearchIcon aria-label='Magnifier icon' size={24} />
          </div>
        )}

        <p
          className={clsx(
            'absolute top-2 block cursor-text text-[12px] leading-[150%] transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-[18px] peer-placeholder-shown:text-darkgray peer-focus:top-2 peer-focus:text-[12px] peer-disabled:cursor-default',
            props.errorMessage
              ? 'text-error peer-focus:text-error'
              : 'text-darkgray peer-focus:text-black',
            props.withMagnifier ? 'left-14' : 'left-4',
          )}
        >
          {props.loading ? <LoadingText textLength={15} /> : props.label}
        </p>

        {props.rightButtonProps?.text && (
          <button
            disabled={props.isDisabled}
            onClick={props.rightButtonProps.onClick}
            className='pl-3 pr-4 text-[14px] leading-[150%] underline outline-offset-[6px]'
          >
            {props.rightButtonProps.text}
          </button>
        )}
      </label>

      {props.errorMessage && (
        <span
          {...errorMessageProps}
          className={clsx(
            'block h-[18px] text-[12px] leading-[150%] text-error transition-opacity',
            props.errorMessage ? 'opacity-100' : 'opacity-0',
          )}
        >
          {props.errorMessage}
        </span>
      )}
    </div>
  )
}
