type ProfileIconProps = {
  size?: number
  className?: string
}

export const ProfileIcon = ({ size = 24, className }: ProfileIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    className={className}
  >
    <path
      d='M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21'
      stroke='#000423'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 11.5C14.2091 11.5 16 9.70914 16 7.5C16 5.29086 14.2091 3.5 12 3.5C9.79086 3.5 8 5.29086 8 7.5C8 9.70914 9.79086 11.5 12 11.5Z'
      stroke='#000423'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
