import { useCallback, useMemo, useState } from 'react'
import { printMoney } from 'shopify-types/helpers'

import Icon from '../../components/Icon'
import ShopifyImage from '../../components/ShopifyImage'

type ProductListItemProps = {
  isLoading: boolean
  variantId?: string
  title: string
  description?: string
  image?: string
  imageAlt?: string
  quantity: number
  productId: string
  price?: string | number
  currency?: string
  deleteButtonText: string
  isQuantityUpdated: boolean
  maxPerItemInCart: number
  accessability: {
    addItemButton: string
    subtractItemButton: string
    deleteItemButton: string
    countIndicator: string
  }
  onItemQuantityUpdate: (data: { quantity: number; productId: string; variantId: string }) => void
  onItemDelete: (data: { productId: string; variantId?: string | undefined }) => Promise<void>
}

export default function ProductListItem({
  variantId,
  title,
  description,
  quantity,
  image,
  imageAlt,
  productId,
  price,
  currency,
  isLoading,
  onItemDelete,
  deleteButtonText,
  onItemQuantityUpdate,
  isQuantityUpdated,
  maxPerItemInCart,
  accessability,
}: ProductListItemProps) {
  const [newQuantity, setNewQuantity] = useState(quantity)

  const trimmedVariantVariantId = useMemo(() => variantId?.split('/').at(-1), [variantId])

  const itemPrice = useMemo(() => {
    if (!price || !currency) return

    const positionPrice = Number(price) * quantity

    return printMoney({ amount: positionPrice, currencyCode: currency })
  }, [currency, price, quantity])

  const imageSizes = useMemo(
    () => ({
      width: {
        desktop: 108,
        mobile: 96,
      },
      height: {
        desktop: 108,
        mobile: 96,
      },
    }),
    [],
  )

  const onAddQuantity = useCallback(() => {
    if (variantId == null) return

    setNewQuantity(prevValue => {
      const quantity = prevValue + 1
      onItemQuantityUpdate({ quantity, productId, variantId })

      return quantity
    })
  }, [variantId, onItemQuantityUpdate, productId])

  const onSubtractQuantity = useCallback(() => {
    if (variantId == null) return

    setNewQuantity(prevValue => {
      const quantity = prevValue - 1
      onItemQuantityUpdate({ quantity, productId, variantId })

      return quantity
    })
  }, [variantId, onItemQuantityUpdate, productId])

  return (
    <div className='flex gap-5 px-5 xl:gap-6 xl:px-10'>
      <ShopifyImage
        src={image ?? ''}
        alt={imageAlt ?? ''}
        width={imageSizes.width}
        height={imageSizes.height}
        imageClassName='h-24 w-24 xl:w-27 xl:h-27 mix-blend-darken'
        className='radius-md flex items-center bg-lightgray'
      />

      <div className='flex grow flex-col gap-4 py-1.5 xl:gap-3.5'>
        <div className='flex flex-col justify-between gap-0.5 xl:flex-row xl:justify-between xl:gap-6'>
          <div className='flex flex-col gap-0.5'>
            <p>{title}</p>

            <p className='text-[14px] text-darkgray'>{description}</p>
          </div>

          <p className='text-[14px] md:text-[18px]'>{itemPrice}</p>
        </div>

        <div className='flex justify-between gap-10'>
          <div className='flex items-center justify-center gap-4 md:gap-3'>
            <button
              className='rounded-pill border border-solid border-black border-opacity-40 p-2.5 text-black transition-colors disabled:border-darkgray disabled:border-opacity-40 disabled:text-darkgray md:p-1.5'
              disabled={newQuantity <= 1 || isLoading}
              onClick={onSubtractQuantity}
              data-testid={`shopping-basket-${trimmedVariantVariantId}-subtract-one`}
              aria-label={accessability.subtractItemButton}
            >
              <Icon name='MdRemove' size={20} />
            </button>

            <h6
              className='select-none text-[18px]'
              data-testid={`shopping-basket-${trimmedVariantVariantId}-variant-quantity`}
              aria-label={`${accessability.countIndicator} ${newQuantity}`}
            >
              {newQuantity}
            </h6>

            <button
              className='rounded-pill border border-solid border-black border-opacity-40 p-2.5 text-black transition-colors disabled:border-darkgray disabled:border-opacity-40 disabled:text-darkgray md:p-1.5'
              disabled={newQuantity >= maxPerItemInCart || isLoading}
              onClick={onAddQuantity}
              data-testid={`shopping-basket-${trimmedVariantVariantId}-add-one`}
              aria-label={accessability.addItemButton}
            >
              <Icon name='MdAdd' size={20} />
            </button>
          </div>

          <button
            disabled={isLoading || isQuantityUpdated}
            onClick={() => onItemDelete({ productId, variantId })}
            aria-label={accessability.deleteItemButton}
            data-testid={`shopping-basket-${trimmedVariantVariantId}-delete-button`}
          >
            <p className='xs select-none text-darkgray'>{deleteButtonText}</p>
          </button>
        </div>
      </div>
    </div>
  )
}
