import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { useWindowSize } from '../../hooks/useWindowSize'
import SearchField from './SearchField'

type SearchBarProps = {
  isOpen: boolean
  isCloseAnimationStarted: boolean
  onClose: () => void
  onCloseAnimationEnd: () => void
  placeholder?: string | null
  onSubmit: (query: string) => void
  searchSuggestion?: {
    title: string
    links: {
      title: string
      id?: string | null
    }[]
  }
  searchButtonLabel?: string
  closeButtonLabel?: string
}

export default function SearchBar({
  isOpen,
  onClose,
  isCloseAnimationStarted,
  onCloseAnimationEnd,
  onSubmit,
  placeholder,
  searchSuggestion,
  searchButtonLabel,
  closeButtonLabel,
}: SearchBarProps) {
  const { isDesktop } = useWindowSize()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()
    }
  }, [isOpen])

  return (
    <div className='xl:relative'>
      <button
        onClick={onClose}
        tabIndex={-1}
        className={clsx(
          'fixed inset-0 top-0 z-30 hidden h-screen bg-black opacity-10',
          isOpen && 'xl:block',
        )}
        aria-hidden={true}
      />

      <div
        className={clsx(
          'absolute top-0 z-50 h-screen w-full bg-white px-5 pt-12 xl:z-30 xl:h-auto xl:border-t xl:border-darkgray xl:px-10 xl:py-10',
          isOpen && !isCloseAnimationStarted
            ? `${isDesktop ? 'openUpToDown' : 'openRightToLeft'}`
            : `${isDesktop ? 'closeUpToDown' : 'closeRightToLeft'}`,
        )}
        onAnimationEnd={onCloseAnimationEnd}
        role='dialog'
        aria-modal={isOpen}
      >
        <SearchField
          onSubmit={onSubmit}
          onCloseButtonPress={onClose}
          searchFieldRef={inputRef}
          placeholder={placeholder}
          searchSuggestion={searchSuggestion}
          searchButtonLabel={searchButtonLabel}
          closeButtonLabel={closeButtonLabel}
        />
      </div>
    </div>
  )
}
