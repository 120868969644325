'use client'

import clsx from 'clsx'

import { ShoppingCartIcon } from '../../custom-icons/ShoppingCartIcon'

type ShoppingBasketButtonProps = {
  onClick: () => void
  itemsCount?: number
  className?: string
}

export default function ShoppingBasketButton({
  onClick,
  className,
  itemsCount,
}: ShoppingBasketButtonProps) {
  return (
    <button
      onClick={onClick}
      aria-label='Warenkorb öffnen'
      data-testid='open-shopping-basket-button'
      className={clsx('relative isolate', className)}
    >
      {itemsCount != null && itemsCount !== 0 && (
        <div className='radius-pill absolute left-3.5 top-0 z-10 flex h-3 min-w-[0.75rem] items-center justify-center border border-white bg-black'>
          <p className='inline-block p-0.5 align-middle text-[8px] leading-[normal] text-white'>
            {itemsCount}
          </p>
        </div>
      )}

      <ShoppingCartIcon size={20} aria-label='Warenkorb' />
    </button>
  )
}
