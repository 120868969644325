import { useRef } from 'react'
import { useButton } from 'react-aria'

import { SearchIcon } from '../../custom-icons/SearchIcon'

type SearchButtonProps = {
  onPress: () => void
  className: string
  searchButtonLabel?: string
}

export default function SearchButton(props: SearchButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  return (
    <button
      type='submit'
      data-testid='global-search-button'
      onClick={props.onPress}
      className={props.className}
      ref={ref}
      aria-label={props.searchButtonLabel}
      {...buttonProps}
    >
      <SearchIcon size={24} />
    </button>
  )
}
