import { FormEvent, RefObject, useCallback, useState } from 'react'

import Icon from '../../components/Icon'
import { SearchIcon } from '../../custom-icons/SearchIcon'
import { useWindowSize } from '../../hooks/useWindowSize'

type SearchFieldProps = {
  onSubmit: (query: string) => void
  onCloseButtonPress: () => void
  searchFieldRef: RefObject<HTMLInputElement>
  placeholder?: string | null
  searchButtonLabel?: string
  closeButtonLabel?: string
  searchSuggestion?: {
    title: string
    links: {
      title: string
      id?: string | null
    }[]
  }
}

export default function SearchField({
  onCloseButtonPress,
  onSubmit,
  searchFieldRef,
  placeholder,
  searchSuggestion,
  searchButtonLabel,
  closeButtonLabel,
}: SearchFieldProps) {
  const [value, setValue] = useState('')
  const { isDesktop } = useWindowSize()

  const onSearchSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      if (value.trim().length === 0) return

      onSubmit(value)

      onCloseButtonPress()
    },
    [onCloseButtonPress, onSubmit, value],
  )

  const onSuggestionClick = useCallback(
    (value: string) => {
      onSubmit(value)

      onCloseButtonPress()
    },
    [onCloseButtonPress, onSubmit],
  )

  return (
    <form onSubmit={onSearchSubmit} role='search'>
      <button
        type='button'
        aria-label={closeButtonLabel}
        className='absolute right-5 top-5 xl:hidden'
        onClick={onCloseButtonPress}
      >
        <Icon name='MdClose' size={24} />
      </button>
      <label className='flex border-b border-black'>
        <button
          type='submit'
          aria-label={searchButtonLabel}
          data-testid='global-submit-search-button'
        >
          <SearchIcon size={isDesktop ? 32 : 24} />
        </button>
        <input
          className='h5 w-full grow px-3 py-4 placeholder:text-darkgray focus:outline-none'
          data-testid='global-search-input-field'
          placeholder={placeholder ?? undefined}
          ref={searchFieldRef}
          value={value}
          onChange={e => setValue(e.target.value)}
          name={placeholder ?? undefined}
          type='text'
        />

        <button
          type='button'
          aria-label={closeButtonLabel}
          className='hidden xl:block'
          onClick={onCloseButtonPress}
        >
          <Icon name='MdClose' size={32} />
        </button>
      </label>

      {searchSuggestion && (
        <div className='mt-10 flex flex-col gap-4 xl:mt-9'>
          <p className='sm text-darkgray'>{searchSuggestion.title}</p>

          <ul className='flex flex-col gap-4'>
            {searchSuggestion.links?.map(({ title, id }) => (
              <li key={id}>
                <button onClick={() => onSuggestionClick(title)}>
                  <h6 className='h6'>{title}</h6>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  )
}
