/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
'use client'

import clsx from 'clsx'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'
import {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Cart, ProductVariant } from 'shopify-types'
import { printMoney } from 'shopify-types/helpers'

import Button from '../../components/Button'
import Icon from '../../components/Icon'
import modalService from '../../service/modalService'
import ProductList from './ProductList'
import UpSell from './Upsell'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type WithUpSell = {
  upSell: {
    isEnabled: true
    variant: ProductVariant
    accessability: {
      addButton: string
    }
  }
  onAddUpSellProduct: () => Promise<void>
}

type WithoutUpSell = {
  upSell?: {
    isEnabled?: false
  }
}

export type UpSell = WithUpSell | WithoutUpSell

type ShoppingBasketModalProps = {
  isOpen: boolean
  title: string
  checkoutButtonText: string
  totalPriceText: string
  deleteItemText: string
  onClose: () => void
  isLoading: boolean
  cart: Cart | null
  maxPerItemInCart: number
  accessability: {
    closeButton: string
    addItemButton: string
    subtractItemButton: string
    deleteItemButton: string
    countIndicator: string
  }
  onItemQuantityUpdate: (
    data: Array<{
      quantity: number
      productId: string
      variantId: string
    }>,
  ) => Promise<void>
  onItemDelete: (data: { productId: string; variantId?: string | undefined }) => Promise<void>
  onCheckoutSubmit: () => void
} & UpSell

const checkUpSellType = (props: UpSell): props is WithUpSell => props.upSell?.isEnabled === true

export default function ShoppingBasketModal({
  isOpen,
  title,
  checkoutButtonText,
  totalPriceText,
  deleteItemText,
  onClose,
  isLoading,
  cart,
  maxPerItemInCart,
  onItemQuantityUpdate,
  onItemDelete,
  onCheckoutSubmit,
  accessability,
  ...upSellProps
}: ShoppingBasketModalProps) {
  const [isCloseAnimationStarted, setIsCloseAnimationStarted] = useState(false)
  const [isCheckoutSubmitting, setIsCheckoutSubmitting] = useState(false)
  const [isProductListScrollable, setIsProductListScrollable] = useState(false)

  const modalRef = useRef<HTMLDialogElement>(null)
  const productListRef = useRef<HTMLDivElement>(null)

  const isUpSellItemInCheckout = useMemo(
    () =>
      cart?.lines.some(
        ({ merchandise }) => merchandise.id === (upSellProps as WithUpSell).upSell?.variant.id,
      ) ?? false,
    [cart?.lines, upSellProps],
  )

  const { oldPrice, discountedPrice } = useMemo(() => {
    const oldPrice =
      cart?.lines.reduce((acc, { merchandise, quantity }) => {
        return acc + (merchandise.compareAtPrice?.amount ?? merchandise.price.amount) * quantity
      }, 0) ?? 0
    const discountedPrice = cart?.cost.totalAmount.amount ?? 0

    return {
      oldPrice: printMoney({
        amount: oldPrice,
        currencyCode: cart?.cost.totalAmount.currencyCode ?? '€',
      }),
      discountedPrice: discountedPrice < oldPrice ? printMoney(cart?.cost.totalAmount) : null,
    }
  }, [cart])

  const onShoppingBasketClose = useCallback(() => {
    if (!modalRef.current) return

    modalRef.current.classList.add('closeLeftToRight')
    setIsCloseAnimationStarted(true)
  }, [])

  const onCloseAnimationEnd = useCallback(() => {
    if (!modalRef.current || !isCloseAnimationStarted) return

    modalRef.current.close()
    setIsCloseAnimationStarted(false)

    onClose()
    modalService.changeModalState('shoppingBasket', false)
  }, [isCloseAnimationStarted, onClose])

  const onModalClick = useCallback(
    (event: MouseEvent<HTMLDialogElement>) => {
      if (!modalRef.current) return

      // check if we click on backdrop
      const rect = modalRef.current.getBoundingClientRect()

      if (
        event.clientX < rect.left ||
        event.clientX > rect.right ||
        event.clientY < rect.top ||
        event.clientY > rect.bottom
      ) {
        onShoppingBasketClose()
      }
    },
    [onShoppingBasketClose],
  )

  const onEscapeClose = useCallback(
    (e: SyntheticEvent<HTMLDialogElement>) => {
      e.preventDefault()

      onShoppingBasketClose()
    },
    [onShoppingBasketClose],
  )

  const handleCheckoutSubmit = useCallback(async () => {
    setIsCheckoutSubmitting(true)

    setTimeout(() => {
      onCheckoutSubmit()

      onShoppingBasketClose()
    })
  }, [onCheckoutSubmit, onShoppingBasketClose])

  useEffect(() => {
    if (isOpen && modalRef.current != null) {
      modalRef.current.showModal()
      modalService.changeModalState('shoppingBasket', true)
      modalRef.current.classList.add('openLeftToRight')
    }
  }, [isOpen])

  useLayoutEffect(() => {
    const handleResize = () => {
      if (productListRef.current) {
        setIsProductListScrollable(
          productListRef.current.scrollHeight > productListRef.current.clientHeight,
        )
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [productListRef])

  return (
    <dialog
      ref={modalRef}
      onAnimationEnd={onCloseAnimationEnd}
      onClick={onModalClick}
      onCancel={onEscapeClose}
      className='fixed bottom-0 top-0 max-h-none max-w-none backdrop:cursor-pointer md:ml-auto md:w-[536px]'
    >
      <div className='flex h-screen w-screen min-w-full flex-col py-5 md:w-[536px] xl:pb-10 xl:pt-14'>
        <header
          className={clsx(
            'flex justify-between px-5 pb-6 xl:px-10',
            isProductListScrollable && 'border-b border-lightgray',
          )}
        >
          <h4 className='h4'>{title}</h4>

          <button
            onClick={onShoppingBasketClose}
            className='static xl:absolute xl:right-6 xl:top-6'
            data-testid='close-shopping-basket-button'
            aria-label={accessability.closeButton}
          >
            <Icon name='MdClose' size={32} />
          </button>
        </header>

        <div ref={productListRef} className='flex grow flex-col overflow-y-auto'>
          <ProductList
            cart={cart}
            isLoading={isLoading}
            onItemDelete={onItemDelete}
            onItemQuantityUpdate={onItemQuantityUpdate}
            deleteItemText={deleteItemText}
            maxPerItemInCart={maxPerItemInCart}
            accessability={{
              addItemButton: accessability.addItemButton,
              subtractItemButton: accessability.subtractItemButton,
              deleteItemButton: accessability.deleteItemButton,
              countIndicator: accessability.countIndicator,
            }}
          />

          <LazyMotion features={motionFeatures} strict>
            <AnimatePresence>
              {checkUpSellType(upSellProps) && !isUpSellItemInCheckout && (
                <m.div
                  layout
                  initial={{ scaleY: 0, opacity: 0 }}
                  animate={{ scaleY: 1, opacity: 1 }}
                  exit={{ scaleY: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{ originY: 1 }}
                >
                  <UpSell
                    {...upSellProps.upSell}
                    onAddUpSellProduct={upSellProps.onAddUpSellProduct}
                    isLoading={isLoading}
                  />
                </m.div>
              )}
            </AnimatePresence>
          </LazyMotion>
        </div>

        <div
          className={clsx(
            'px-5 pt-5 xl:px-10',
            isProductListScrollable && 'shadow-[0_-8px_12px_0_rgba(0,0,0,0.05)]',
          )}
        >
          <div className='flex items-center justify-between gap-2 pb-4 xl:pb-5'>
            <p className='sm'>{totalPriceText}</p>

            <div className='flex gap-2'>
              <h5
                className={clsx('h5 whitespace-nowrap', discountedPrice != null && 'line-through')}
              >
                {oldPrice}
              </h5>

              {discountedPrice != null && (
                <h5 className='h5 whitespace-nowrap'>{discountedPrice}</h5>
              )}
            </div>
          </div>

          <Button
            data-testid='shopping-basket-checkout-button'
            onPress={handleCheckoutSubmit}
            isLoading={isCheckoutSubmitting}
            appearance='dark'
            className='w-full !max-w-full'
          >
            {checkoutButtonText}
          </Button>
        </div>
      </div>
    </dialog>
  )
}
