'use client'

import { useState } from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'

type RegistrationBannerProps = {
  title?: string | null
  description?: string | null
  label?: string | null
  buttonText?: string | null
  onSubmit?: (email: string) => void
}

export default function RegistrationBanner({
  title,
  description,
  label,
  buttonText,
}: RegistrationBannerProps) {
  const [value, setValue] = useState('')

  return (
    <div className='w-full rounded-md bg-ecruwhite px-5 py-8 lg:mx-auto lg:px-14 lg:py-16'>
      <div className='flex flex-col items-center justify-evenly gap-10 lg:flex-row lg:items-start lg:gap-[80px]'>
        <div className='w-full text-center lg:text-left'>
          {title && <p className='h4 mb-4'>{title}</p>}

          {description && <p className='sm '>{description}</p>}
        </div>

        <div className='my-3 w-full'>
          <form
            action='//beyer-soehne.us2.list-manage.com/subscribe/post?u=f5400cee295d6e20f08f0a194&amp;id=f0a1c080f8'
            id='mc-embedded-subscribe-form'
            name='mc-embedded-subscribe-form'
            method='post'
            target='_blank'
            className='flex w-full flex-1 flex-col gap-3 sm:items-center lg:items-baseline'
          >
            <Input
              className='w-full'
              name='EMAIL'
              label={label ?? ''}
              value={value}
              onChange={setValue}
              isTransparent
            />

            <Button
              className='w-full'
              textClassName='xl:!text-[18px]'
              type='submit'
              appearance='dark'
              withArrow
            >
              {buttonText ?? ''}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
