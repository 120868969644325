import clsx from 'clsx'
import { LazyMotion, m } from 'framer-motion'
import Link from 'next/link'
import { useMemo, useState } from 'react'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type NavLinkListProps = {
  menuItems?: {
    id?: string | null
    url: string
    title: string
    isActive?: boolean
  }[]
  isPreview?: boolean
  onClick: () => void
}

export default function NavLinkList({ menuItems, isPreview, onClick }: NavLinkListProps) {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const mappedItems = useMemo(
    () =>
      isPreview
        ? menuItems?.map((item, index) => ({
            ...item,
            isActive: index === activeItemIndex,
          }))
        : menuItems,
    [activeItemIndex, isPreview, menuItems],
  )

  return (
    <LazyMotion features={motionFeatures} strict>
      <ul className='hidden items-center gap-2 rounded-pill bg-lightgray px-4 py-2 xl:flex'>
        {mappedItems?.map(({ url, title, id, isActive }, index) => {
          return (
            <li key={id ?? url} className='h-max'>
              {isPreview ? (
                <button
                  onClick={() => {
                    setActiveItemIndex(index)
                    onClick()
                  }}
                  className='group relative flex flex-col items-center px-5 py-2 no-underline'
                >
                  <NavLinkContent title={title} isActive={isActive ?? false} />
                </button>
              ) : (
                <Link
                  href={url}
                  onClick={onClick}
                  className='group relative flex flex-col items-center px-5 py-2 no-underline'
                >
                  <NavLinkContent title={title} isActive={isActive ?? false} />
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </LazyMotion>
  )
}

type NavLinkContentProps = {
  isActive: boolean
  title: string
}

function NavLinkContent({ isActive, title }: NavLinkContentProps) {
  return (
    <>
      {isActive && (
        <m.div
          className='absolute bottom-0 left-0 right-0 top-0 z-10 rounded-pill bg-black'
          layoutId='backdrop'
          style={{ originY: '0px' }}
        />
      )}
      <div
        className={
          'absolute bottom-0 left-0 right-0 top-0 rounded-pill bg-black opacity-0 transition-opacity group-hover:opacity-10'
        }
      />

      <p
        className={clsx(
          'sm xl:xs whitespace-nowrap !transition !duration-500',
          isActive ? 'z-20 text-white opacity-100' : 'text-black group-hover:opacity-80',
        )}
      >
        {title}
      </p>
    </>
  )
}
