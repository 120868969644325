'use client'
import React from 'react'
import ReactCookieFirst from 'react-cookiefirst'

type CookieBannerProps = {
  apiKey: string
}

function CookieBanner({ apiKey }: CookieBannerProps) {
  return <ReactCookieFirst apiKey={apiKey} />
}

export default CookieBanner
