import clsx from 'clsx'

type ShoppingCartIconProps = {
  size?: number
  className?: string
}

export const ShoppingCartIcon = ({ size = 20, className }: ShoppingCartIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size * 0.95}
    viewBox={`0 0 20 19`}
    fill='none'
    className={clsx('mb-[2.818px] ml-0.5 mr-1 mt-1', className)}
  >
    <path
      d='M7.54548 18.1818C7.99735 18.1818 8.36366 17.8155 8.36366 17.3636C8.36366 16.9117 7.99735 16.5454 7.54548 16.5454C7.09361 16.5454 6.72729 16.9117 6.72729 17.3636C6.72729 17.8155 7.09361 18.1818 7.54548 18.1818Z'
      stroke='#000423'
      strokeWidth={1.63636}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.5455 18.1818C16.9973 18.1818 17.3637 17.8155 17.3637 17.3636C17.3637 16.9117 16.9973 16.5454 16.5455 16.5454C16.0936 16.5454 15.7273 16.9117 15.7273 17.3636C15.7273 17.8155 16.0936 18.1818 16.5455 18.1818Z'
      stroke='#000423'
      strokeWidth={1.63636}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 1H4.27273L6.46545 11.9555C6.54027 12.3321 6.7452 12.6705 7.04436 12.9113C7.34351 13.1522 7.71784 13.2801 8.10182 13.2727H16.0545C16.4385 13.2801 16.8129 13.1522 17.112 12.9113C17.4112 12.6705 17.6161 12.3321 17.6909 11.9555L19 5.09091H5.09091'
      stroke='#000423'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
