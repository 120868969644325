type SearchIconProps = {
  size?: number
  className?: string
}

export const SearchIcon = ({ size = 24, className }: SearchIconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    className={className}
  >
    <path
      d='M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z'
      stroke='#000423'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 20L16 16'
      stroke='#000423'
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
