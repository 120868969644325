'use client'

import clsx from 'clsx'
import { AnimatePresence, LazyMotion, m } from 'framer-motion'
import { ElementRef, useMemo, useRef, useState } from 'react'

import { useWindowSize } from '../hooks/useWindowSize'
import Image from './Image'

const motionFeatures = () => import('ui/helpers/motionFeatures').then(res => res.default)

type ShopifyImageProps = {
  src: string
  alt: string
  width: {
    desktop: number
    mobile: number
  }
  height: {
    desktop: number
    mobile: number
  }
  /**
   * Specify image className due to proper render size on SSR.
   * @warning DO NOT pass `width` or `height` with square parentheses `[]`, this might cause problems
   * as tailwind needs to pre-parse those style and some styles might be missing afterwards
   */
  imageClassName: string
}

export default function ShopifyImage({
  src,
  alt,
  width,
  height,
  className,
  imageClassName,
  ...pictureProps
}: JSX.IntrinsicElements['picture'] & ShopifyImageProps) {
  const [isImageLoaded, setImageIsLoaded] = useState(false)

  const { isDesktop } = useWindowSize()

  const elementRef = useRef<ElementRef<'picture'>>(null)

  const srcSet = useMemo(() => {
    const mobileImage = `${src}&height=${height.mobile * 1.5}&width=${
      width.mobile * 1.5
    }&crop=center ${width.mobile}w`

    const desktopImage = `${src}&height=${height.desktop * 1.5}&width=${
      width.desktop * 1.5
    }&crop=center ${width.desktop}w`

    return `${mobileImage}, ${desktopImage}`
  }, [height.desktop, height.mobile, src, width.desktop, width.mobile])

  return (
    <picture
      {...pictureProps}
      ref={elementRef}
      className={clsx('transition-[filter]', !isImageLoaded && 'blur', className)}
    >
      <source srcSet={srcSet} sizes={`(min-width: 1440px) ${width.desktop}px, ${width.mobile}px`} />

      <Image
        src={`${src}&height=${height.desktop * 1.5}&width=${width.desktop * 1.5}&crop=center`}
        alt={alt}
        width={width[isDesktop ? 'desktop' : 'mobile']}
        quality={100}
        height={height[isDesktop ? 'desktop' : 'mobile']}
        onLoad={() => {
          setImageIsLoaded(true)
        }}
        unoptimized
        className={clsx(
          `transition-opacity`,
          isImageLoaded ? 'opacity-100' : 'opacity-0',
          imageClassName,
        )}
      />

      {!isImageLoaded && (
        <LazyMotion features={motionFeatures} strict>
          <AnimatePresence>
            <m.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={clsx('absolute inset-0 animate-pulse bg-gray', imageClassName)}
            />
          </AnimatePresence>
        </LazyMotion>
      )}
    </picture>
  )
}
