'use client'

import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/de'

import { Image } from 'cms-types'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import { PayloadImage } from '../../components/PayloadImage'
import LanguageModal from '../LanguageModal/LanguageModal'
import RegistrationBanner from './RegistrationBanner'

type FooterProps = {
  registrationBanner?: {
    title?: string | null
    description?: string | null
    label?: string | null
    buttonText?: string | null
  }
  isRegistrationBannerEnabled: boolean
  logo?: Image
  logoUrl: string
  columns: {
    columnTitle: string
    pages: {
      id?: string | null
      url: string
      title: string
    }[]
    id?: string | null
  }[]
  footerLegalInformation: {
    id?: string | null
    url: string
    title: string
  }[]
  socials: {
    icon?: Image
    url: string
    id?: string | null
  }[]
  languageTypography: {
    switchTitle: string
    modalTitle: string
    modalButtonText: string
  }
  locale: string | undefined
  onChangeLanguage: (language: string) => void
}

export default function Footer({
  registrationBanner,
  isRegistrationBannerEnabled,
  columns,
  socials,
  logo,
  logoUrl,
  footerLegalInformation,
  languageTypography: { switchTitle, modalTitle, modalButtonText },
  locale,
  onChangeLanguage,
}: FooterProps) {
  const [showLanguageModal, setShowLanguageModal] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)

  function convertedLocale(locale: string | null) {
    if (!locale) return ''
    const displayNames = new Intl.DisplayNames([locale], { type: 'language' })
    const converted = displayNames.of(locale)

    return converted ? converted.charAt(0).toUpperCase() + converted.slice(1) : ''
  }

  useEffect(() => {
    setSelectedLanguage(locale || null)
  }, [locale])

  return (
    <div className='flex flex-col gap-20 px-5 py-10 xl:px-10 xl:py-20'>
      {isRegistrationBannerEnabled && registrationBanner && (
        <RegistrationBanner {...registrationBanner} />
      )}

      <nav className='grid grid-cols-1 gap-y-10 xl:grid-cols-2'>
        <ul className='mb-2 flex flex-col gap-12 lg:order-2 lg:flex-row lg:gap-16 xl:mb-0'>
          {columns.map(({ columnTitle, pages, id }, index) => (
            <li key={id ?? columnTitle + index} className='flex flex-col gap-6'>
              <p className='font-bold'>{columnTitle}</p>

              <ul className='flex flex-col gap-4'>
                {pages.map(({ title, url, id }) => (
                  <li key={id ?? url}>
                    <Link href={url} className='no-underline'>
                      <p className='sm underline-animated'>{title}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <div className='mb-10 flex flex-row justify-evenly gap-8 lg:order-4 xl:mb-2'>
          <div className='flex w-full flex-col'>
            <p className='mb-6 font-bold'>{switchTitle}</p>

            <button
              className=' border-1 rounded-sm border border-darkblack p-4 text-left'
              onClick={() => setShowLanguageModal(true)}
            >
              {convertedLocale(selectedLanguage)}
            </button>
          </div>

          <div className='hidden lg:block lg:w-full' />
          <div className='hidden w-full lg:block' />
        </div>

        <ul className='mb-10 flex gap-8 lg:order-6 lg:mb-2'>
          {socials.map(({ id, url, icon }) => (
            <li key={id ?? url}>
              <Link href={url}>
                <PayloadImage sizes='24px' skipBlur className='h-6 w-6' src={icon} />
              </Link>
            </li>
          ))}
        </ul>

        <Link
          href={logoUrl}
          className='relative mr-auto h-12 w-56 max-w-full lg:order-1 lg:h-20 lg:w-96'
        >
          <PayloadImage
            src={logo}
            sizes='(min-width: 1024px) 384px, 224px'
            skipBlur
            objectFit='contain'
          />
        </Link>

        <ul className='flex flex-col gap-2.5 lg:order-8 lg:flex-row lg:gap-8'>
          {footerLegalInformation.map(({ id, url, title }) => (
            <li key={id ?? url}>
              <Link href={url} className='no-underline'>
                <p className='xs underline-animated text-darkgray'>{title}</p>
              </Link>
            </li>
          ))}
        </ul>

        <div className='hidden lg:order-3 xl:block' />
        <div className='hidden lg:order-5 xl:block' />
        <div className='hidden lg:order-7 xl:block' />
      </nav>

      {showLanguageModal && (
        <LanguageModal
          showModal={showLanguageModal}
          onClose={() => setShowLanguageModal(false)}
          modalTitle={modalTitle}
          confirmText={modalButtonText}
          onChangeLanguage={onChangeLanguage}
          activeLanguage={selectedLanguage}
        />
      )}
    </div>
  )
}
