import { useMemo } from 'react'
import { ProductVariant } from 'shopify-types'
import { printMoney } from 'shopify-types/helpers'

import Icon from '../../components/Icon'
import Image from '../../components/Image'

type UpSellProps = {
  variant: ProductVariant
  onAddUpSellProduct: () => Promise<void>
  isLoading: boolean
  accessability: {
    addButton: string
  }
}

export default function UpSell({
  variant,
  onAddUpSellProduct,
  isLoading,
  accessability,
}: UpSellProps) {
  const trimmedVariantVariantId = useMemo(() => variant.id.split('/').at(-1), [variant.id])

  return (
    <div className='hidden flex-col gap-4 px-10 py-5 xl:flex'>
      {variant.title && <p className='sm'>{variant.title}</p>}

      <div className='radius-sm flex items-center justify-between gap-4 bg-ecruwhite pr-4'>
        <Image
          src={variant.image.src ?? ''}
          alt={variant.image.altText ?? ''}
          width={108}
          height={108}
          className='radius-sm !rounded-r-none mix-blend-multiply'
        />

        <div className='my-4 flex max-h-min flex-col gap-1'>
          <p className='sm'>{variant.product.title}</p>

          <p className='xs line-clamp-2 w-64 text-ellipsis'>
            {variant.product.truncatedDescription}
          </p>
        </div>

        <div className='my-4 flex flex-col items-end gap-2'>
          <p className='xs'>{printMoney(variant.price)}</p>

          <button
            data-testid={`upsell-add-${trimmedVariantVariantId}`}
            onClick={onAddUpSellProduct}
            disabled={isLoading}
            className='radius-sm p-3 text-black outline outline-1 outline-black disabled:text-darkgray disabled:outline-darkgray'
            aria-label={accessability.addButton}
          >
            <Icon name='MdAdd' size={24} />
          </button>
        </div>
      </div>
    </div>
  )
}
