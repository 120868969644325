import NextBaseImage, { ImageProps } from 'next/image'

export default function Image(props: ImageProps) {
  return (
    <NextBaseImage
      {...props}
      loader={({ src }) => {
        return src
      }}
    />
  )
}
