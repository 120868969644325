import Icon from '../Icon'

type RadioGroupProps = {
  data: {
    label: string
    value: string
  }[]
  selectedItemValue: string
  onChange: (value: string) => void
}

export default function RadioGroup({ data, selectedItemValue, onChange }: RadioGroupProps) {
  return (
    <div>
      {data.map((item, index) => {
        const isSelected = item.value === selectedItemValue

        return (
          <button
            key={index}
            className='mb-4 flex items-center last:mb-0'
            onClick={() => onChange(item.value)}
          >
            {isSelected ? (
              <Icon name='MdOutlineRadioButtonChecked' size={20} />
            ) : (
              <Icon name='MdOutlineRadioButtonUnchecked' size={20} />
            )}

            <p className='ml-4'>{item.label}</p>
          </button>
        )
      })}
    </div>
  )
}
