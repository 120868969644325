'use client'

import { ComponentProps } from 'react'
import { ToastContainer } from 'react-toastify'

export const AlertContainer = (props: ComponentProps<typeof ToastContainer>) => {
  return (
    <ToastContainer
      autoClose={false}
      closeButton={false}
      icon={false}
      {...props}
      className='!oveflow-hidden !rounded-md'
      bodyClassName='!rounded-md !oveflow-hidden'
      toastClassName='!oveflow-hidden !rounded-md'
    />
  )
}
